<template>
  <div>
    <div class="simiot-table-operation-buttons">
      <a-button type="primary" @click="fetchData">
        刷新
      </a-button>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findUserBusinessTypeStatistics } from '@/api/user_business_type_statistic'

export default {
  name: 'UserBusinessTypeList',
  data() {
    return {
      data: [],
      loading: true
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '用户业务模式名称',
          dataIndex: 'name'
        },
        {
          title: '所属套餐数量',
          dataIndex: 'product_count'
        },
        {
          title: '所属移动卡号数量',
          dataIndex: 'mobile_card_count'
        },
        {
          title: '所属电信卡号数量',
          dataIndex: 'telecom_card_count'
        },
        {
          title: '所属联通卡号数量',
          dataIndex: 'unicom_card_count'
        },
        {
          title: '卡号总数',
          dataIndex: 'total_card_count'
        }
      ]
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    fetchData() {
      this.loading = true
      findUserBusinessTypeStatistics().then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
